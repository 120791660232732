body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  background-image: url('bg.png');
  background-size: cover;
  background-attachment: fixed;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/* 
h1, h2 {
font-family: 'Silkscreen', cursive;
}

 */

.blinker {
  min-width: 10px;
  min-height: 20px;
  color: '#ec407a';
}
